<template>
    <transition v-if="parentId || members" name="fade">
        <div v-if="$fetchState.pending || isPending" class="members">
            <section class="members__section">
                <uro-member>
                    <template #picture>
                        <content-placeholders-img style="width: 80px; height: 80px;" />
                    </template>

                    <template #default>
                        <header>
                            <h5><content-placeholders-heading /></h5>
                        </header>
                        <content-placeholders-text :lines="1" />
                    </template>
                </uro-member>
            </section>

            <section class="members__section">
                <uro-member>
                    <template #default>
                        <header>
                            <h5><content-placeholders-heading /></h5>
                        </header>

                        <content-placeholders-text :lines="1" />
                    </template>
                </uro-member>
            </section>

            <section class="members__section">
                <uro-member v-for="i in [1,2,3,4,5,6,7,8,9,10,11]" :key="`member${i}`">
                    <template #default>
                        <header>
                            <h5><content-placeholders-heading /></h5>
                        </header>

                        <content-placeholders-text :lines="1" />
                    </template>
                </uro-member>
            </section>
        </div>

        <div v-else class="members" :data-width="isFaculty ? 2 : 3">
            <section v-if="isFaculty && members && members.length" class="members__section">
                <uro-member v-for="(member, index) in members" :key="`member${index}`">
                    <template v-if="member.memberPicture && member.memberPicture.length" #picture>
                        <img :src="member.memberPicture[0].url" alt="" loading="lazy" width="80">
                    </template>

                    <template #default>
                        <header>
                            <h5>{{ member.memberTitle }} {{ member.memberInitials }} {{ member.memberLastName }} <template v-if="member.memberCountryCode">({{ member.memberCountryCode }})</template></h5>
                            <div>{{ member.memberType }}</div>
                        </header>
                    </template>
                </uro-member>
            </section>

            <section v-if="!isFaculty && chairs && chairs.length" class="members__section">
                <uro-member v-for="(chair, index) in chairs" :key="`chair${index}`">
                    <template v-if="chair.memberPicture && chair.memberPicture.length" #picture>
                        <img :src="chair.memberPicture[0].url" alt="" loading="lazy" width="80">
                    </template>

                    <template #default>
                        <header>
                            <h5>{{ chair.memberTitle }} {{ chair.memberInitials }} {{ chair.memberLastName }} <template v-if="chair.memberCountryCode">({{ chair.memberCountryCode }})</template></h5>
                            <div v-for="(type, typeIndex) in getCleanMemberTypes(chair)" :key="`chair-type${typeIndex}`">{{ type }}</div>
                        </header>
                        <a v-if="chair.conflictOfInterests && chair.conflictOfInterests.length" href="#" @click.prevent="openModal = chair">
                            Conflict of interest
                        </a>
                    </template>
                </uro-member>
            </section>

            <section v-if="!isFaculty && coChairs && coChairs.length" class="members__section">
                <uro-member v-for="(coChair, index) in coChairs" :key="`co${index}`">
                    <template v-if="coChair.memberPicture && coChair.memberPicture.length" #picture>
                        <img :src="coChair.memberPicture[0].url" alt="" loading="lazy" width="80">
                    </template>

                    <template #default>
                        <header>
                            <h5>{{ coChair.memberTitle }} {{ coChair.memberInitials }} {{ coChair.memberLastName }} <template v-if="coChair.memberCountryCode">({{ coChair.memberCountryCode }})</template></h5>
                            <div v-for="(type, typeIndex) in getCleanMemberTypes(coChair)" :key="`co-type${typeIndex}`">{{ type }}</div>
                        </header>
                        <a v-if="coChair.conflictOfInterests && coChair.conflictOfInterests.length" href="#" @click.prevent="openModal = coChair">
                            Conflict of interest
                        </a>
                    </template>
                </uro-member>
            </section>

            <section v-if="!isFaculty && viceChairs && viceChairs.length" class="members__section">
                <uro-member v-for="(viceChair, index) in viceChairs" :key="`vice${index}`">
                    <template #default>
                        <header>
                            <h5>{{ viceChair.memberTitle }} {{ viceChair.memberInitials }} {{ viceChair.memberLastName }} <template v-if="viceChair.memberCountryCode">({{ viceChair.memberCountryCode }})</template></h5>
                            <div v-for="(type, typeIndex) in getCleanMemberTypes(viceChair)" :key="`vice-type${typeIndex}`">{{ type }}</div>
                        </header>
                        <a v-if="viceChair.conflictOfInterests && viceChair.conflictOfInterests.length" href="#" @click.prevent="openModal = viceChair">
                            Conflict of interest
                        </a>
                    </template>
                </uro-member>
            </section>

            <section v-if="!isFaculty && editorsInChief && editorsInChief.length" class="members__section">
                <uro-member v-for="(editorInChief, index) in editorsInChief" :key="`editor-in-chief${index}`">
                    <template #default>
                        <header>
                            <h5>{{ editorInChief.memberTitle }} {{ editorInChief.memberInitials }} {{ editorInChief.memberLastName }} <template v-if="editorInChief.memberCountryCode">({{ editorInChief.memberCountryCode }})</template></h5>
                            <div v-for="(type, typeIndex) in getCleanMemberTypes(editorInChief)" :key="`editor-in-chief-type${typeIndex}`">{{ type }}</div>
                        </header>
                        <a v-if="editorInChief.conflictOfInterests && editorInChief.conflictOfInterests.length" href="#" @click.prevent="openModal = editorInChief">
                            Conflict of interest
                        </a>
                    </template>
                </uro-member>
            </section>

            <section v-if="!isFaculty && allNonChairMembers && allNonChairMembers.length" class="members__section">
                <uro-member v-for="(member, index) in allNonChairMembers" :key="`member-${index}`">
                    <template #default>
                        <header>
                            <h5>{{ member.memberTitle }} {{ member.memberInitials }} {{ member.memberLastName }} <template v-if="member.memberCountryCode">({{ member.memberCountryCode }})</template></h5>
                            <div v-for="(type, typeIndex) in getCleanMemberTypes(member)" :key="`member-type${typeIndex}`">{{ type }}</div>
                        </header>
                        <a v-if="member.conflictOfInterests && member.conflictOfInterests.length" href="#" @click.prevent="openModal = member">
                            Conflict of interest
                        </a>
                    </template>
                </uro-member>
            </section>

            <uro-modal :is-visible="!!openedConflict" @close="openModal = null">
                <template v-if="!!openedConflict">
                    <h4>{{ openedConflict.title }} - conflict of interest</h4>
                    Updated: {{ openedConflict.date }}
                    <p v-for="(conflict, type) in openedConflict.conflictsByType" :key="`conflict${type}`">
                        <strong>{{ type }}</strong><br>
                        <span>{{ getCompanies(conflict.companies) }}</span>
                    </p>
                </template>
            </uro-modal>
        </div>
    </transition>
</template>

<script>
import { groupBy, orderBy } from 'lodash';

import { compareDesc, parseISO } from 'date-fns';
import { formats, formatSingleDate } from '~/helpers/date';

import membersQuery from '~/graphql/queries/members/members.graphql';

import UroMember from '~/patterns/atoms/member/member.vue';
import UroModal from '~/patterns/atoms/modal/modal.vue';

export default {
    components: {
        UroMember,
        UroModal
    },

    props: {
        isPending: {
            type: Boolean,
            required: false,
            default: false
        },
        parentId: {
            type: Number,
            required: false,
            default: null
        },
        members: {
            type: Array,
            required: false,
            default: () => []
        },
        showAffiliatesAssociates: {
            type: Boolean,
            required: false,
            default: true
        },
        isFaculty: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            allMembers: null,
            openModal: null
        };
    },

    async fetch() {
        if (this.parentId) {
            const { data } = await this.$gql.executeQuery(membersQuery, { parent: this.parentId });
            this.allMembers = data?.entries;
        } else {
            this.allMembers = this.members;
        }
    },

    computed: {
        chairs() {
            const memberArray = this.allMembers?.filter(member => member.memberType?.match(/^(secretary[- ]general(?![- ]?elect)|chair(man)?(?![- ]?elect))/i));
            return orderBy(memberArray, ['memberLastName'], ['asc']);
        },
        coChairs() {
            const memberArray = this.allMembers?.filter(member => member.memberType?.match(/^(secretary[- ]general(?![- ]?elect)|co[- ]chair(man)?(?![- ]?elect))/i));
            return orderBy(memberArray, ['memberLastName'], ['asc']);
        },
        viceChairs() {
            const memberArray = this.allMembers?.filter(member => member.memberType?.match(/^(adjunct[- ]secretary[- ]general(?![- ]?elect)|vice[- ]chair(man)?(?![- ]?elect))/i));
            return orderBy(memberArray, ['memberLastName'], ['asc']);
        },
        editorsInChief() {
            const memberArray = this.allMembers?.filter(member => member.memberType?.match(/^(editor[- ]in[- ]chief)/i));
            return orderBy(memberArray, ['memberLastName'], ['asc']);
        },
        consultants() {
            const memberArray = this.allMembers?.filter(member => member.memberType?.match(/^(consultant)/i));
            return orderBy(memberArray, ['memberLastName'], ['asc']);
        },
        exOfficios() {
            const memberArray = this.allMembers?.filter(member => member.memberType?.match(/^(ex-officio)/i));
            return orderBy(memberArray, ['memberLastName'], ['asc']);
        },
        honoraryMembers() {
            const memberArray = this.allMembers?.filter(member => member.memberType?.match(/^(honorary[- ]member)/i));
            return orderBy(memberArray, ['memberLastName'], ['asc']);
        },
        affiliates() {
            const memberArray = this.allMembers?.filter(member => member.memberType?.match(/^(affiliate)/i));
            return orderBy(memberArray, ['memberLastName'], ['asc']);
        },
        associates() {
            const memberArray = this.allMembers?.filter(member => member.memberType?.match(/^(associate)/i));
            return orderBy(memberArray, ['memberLastName'], ['asc']);
        },
        regularMembers() {
            // Filter regular members
            let memberArray = this.allMembers?.filter(member =>
                !this.chairs.includes(member) &&
                !this.coChairs.includes(member) &&
                !this.viceChairs.includes(member) &&
                !this.editorsInChief.includes(member) &&
                !this.consultants.includes(member) &&
                !this.exOfficios.includes(member) &&
                !this.honoraryMembers.includes(member) &&
                !this.affiliates.includes(member) &&
                !this.associates.includes(member)
            );
            // Change member-type for regular members to "member"
            memberArray = memberArray.map((member) => {
                return { ...member, cleanType: 'Member' };
            });
            return orderBy(memberArray, ['memberLastName'], ['asc']);
        },
        allNonChairMembers() {
            if (this.showAffiliatesAssociates) {
                return [].concat(this.regularMembers, this.affiliates, this.associates, this.consultants, this.exOfficios, this.honoraryMembers);
            }
            return [].concat(this.regularMembers, this.associates, this.consultants, this.exOfficios, this.honoraryMembers);
        },
        openedConflict() {
            if (this.openModal) {
                const groupedConflicts = groupBy(this.openModal.conflictOfInterests, item => item.type);
                const conflictsByType = {};
                let date = null;

                Object.keys(groupedConflicts).forEach((type) => {
                    groupedConflicts[type].forEach((conflict) => {
                        // Group by type and merge companies
                        if (!conflictsByType[type]) {
                            conflictsByType[type] = {
                                type: conflict.type,
                                companies: [conflict.company]
                            };
                        } else {
                            conflictsByType[type].companies.push(conflict.company);
                        }
                        // Set the date to the highest value
                        if (conflict.submittedOn) {
                            // Example input is "2019-05-24"
                            const parsedDate = parseISO(conflict.submittedOn);
                            if (!date || compareDesc(date, parsedDate)) {
                                date = parsedDate;
                            }
                        }
                    });
                });

                return {
                    title: this.openModal.title,
                    date: formatSingleDate(date, formats.numericDate),
                    conflictsByType
                };
            }
            return null;
        }
    },

    methods: {
        getCompanies(companies) {
            // Deduplicate and join
            return companies.filter((name, index) => {
                return companies.indexOf(name) === index;
            }).join(', ');
        },
        getCleanMemberTypes(member) {
            return member.cleanType ? [member.cleanType] : member.memberType ? member.memberType.split(' & ') : [];
        }
    }
};
</script>

<style lang="less" src="./members.less" />
