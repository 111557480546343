<template>
    <dn-modal v-bind="$attrs" class="uro-modal" v-on="$listeners" @close="$emit('close')">
        <template #close-button>
            <uro-icon class="dn-modal__close dn-icon--square" icon="close" @click.native="$emit('close')" />
        </template>

        <slot />
    </dn-modal>
</template>

<script>
import DnModal from '@digitalnatives/modal';

export default {
    components: {
        DnModal
    }
};
</script>

<style lang="less">
.uro-modal.dn-modal {
    --border-radius: var(--borderRadius-sm);
    --box-shadow: 0 0 5px rgba(0, 0, 0, .25);
    --max-width: 38.75rem;
    --padding: 2rem;

    .dn-modal__close {
        font-size: 1.75rem;
    }

    @media @q-md-min {
        .dn-modal__close {
            top: var(--padding);
            right: var(--padding);
            font-size: 2rem;
        }
    }

}

.dn-modal__main {
    .typography-text;

    /** Compensate for close-button placement */
    padding-right: calc(var(--padding) * 2);
}
</style>
